import { Button, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Other = () => {
  const { t } = useTranslation();
  const basePath = "home.other.";

  return (
    <section className="check">
      <Row>
        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
          <div className="box">
            <h4>{t(basePath + "title")}</h4>
            <p>{t(basePath + "desc")}</p>
            <div className="btn">
              <Button href="https://t.me/BoostExWallet" type="primary">
                {t(basePath + "btn")}
              </Button>
            </div>
          </div>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24} xs={24} className="hidden-sm">
          <div className="box">
            <img src={require("../../assets/img/check2.png")} alt="" />
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Other;
