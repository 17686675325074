import { Col, Row } from "antd";
import React from "react";
import { IconCopy } from "../utils/Icons/index";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const basePath = "navBar.";

  return (
    <section>
      <footer>
        <Row align="middle">
          <Col xl={8} lg={8} md={24} sm={24} xs={24} className="order-2">
            <img
              src={require("../assets/img/logo.svg").default}
              className="logo"
              alt=""
            />
          </Col>
          <Col xl={16} lg={16} md={24} sm={24} xs={24}>
            <Row>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <h5>{t(basePath + "items.item1")}</h5>
                <ul>
                  <li>
                    <a href="/card-to-card">{t(basePath + "items.item3")}</a>
                  </li>
                  <li>
                    <a href="/balance">{t(basePath + "items.item5")}</a>
                  </li>
                  <li>
                    <a href="/checks">{t(basePath + "items.item7")}</a>
                  </li>
                  <li>
                    <a href="/convertation">{t(basePath + "items.item4")}</a>
                  </li>
                  <li>
                    <a href="/passive-income">{t(basePath + "items.item6")}</a>
                  </li>
                  <li>
                    <a href="/team">{t(basePath + "items.item8")}</a>
                  </li>
                </ul>
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <h5>{t(basePath + "items.item2")}</h5>
                <a href="/docru.pdf" className="doc">
                  {t(basePath + "doc")}{" "}
                  <IconCopy width="31" height="31" fill="#2254ad" />
                </a>
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <h5>FAQ</h5>
              </Col>
            </Row>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <h5 className="mt-4">{t(basePath + "social")}</h5>
                <div className="social">
                  <a
                    href="https://www.facebook.com/profile.php?id=61559199917275&locale=ru_RU"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/social/fb.svg").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/boostex_wallet_official/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/social/inst.svg").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://x.com/boostex_wallet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/social/x.svg").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCZUUqBkrCWWeaEtVTf7c2Ug"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/social/yt.svg").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.reddit.com/user/Icy_Blackberry1303/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/social/red.svg").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://t.me/BoostExWallet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/social/tl.svg").default}
                      alt=""
                    />
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <h5 className="mt-4">{t(basePath + "support")}</h5>
                <div className="support">
                  <a
                    href="https://aitech.io/#about"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/support/solidus.jpeg")}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.globalfounderscapital.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/support/gfc.jpeg")}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.horizonsventures.com/portfolio"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/support/horizons.jpeg")}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://peakinvestgroup.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../assets/img/support/peak.jpeg")}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://rockawayx.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={require("../assets/img/support/r.jpeg")} alt="" />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>
    </section>
  );
};

export default Footer;
