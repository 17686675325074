import { Button, Col, Row } from "antd";
import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Convertation = () => {
  const { t } = useTranslation();
  const basePath = "convertation.";

  return (
    <div className="convertation">
      <NavBar />

      <section className="banner">
        <Row align="middle">
          <Col xl={14} lg={14} md={14} sm={24} xs={24}>
            <h1>{t(basePath + "title")}</h1>
            <p>{t(basePath + "desc")}</p>
            <div className="btn-group">
              <Button href="https://t.me/BoostExWallet" type="primary">
                {t(basePath + "btn")}
              </Button>
            </div>
          </Col>
          <Col xl={10} lg={10} md={10} sm={24} xs={24}>
            <img src={require("../assets/img/aero.png")} alt="" />
          </Col>
        </Row>
      </section>

      <section className="info">
        <div className="box">
          <Row>
            <Col xl={14} lg={14} md={24} sm={24} xs={24}>
              <img src={require("../assets/img/triple_planet.png")} alt="" />
            </Col>
            <Col xl={10} lg={10} md={24} sm={24} xs={24}>
              <h3>{t(basePath + "box1.title")}</h3>
              <p>{t(basePath + "box1.item1")}</p>
              <p>{t(basePath + "box1.item2")}</p>
              <p>{t(basePath + "box1.item3")}</p>
              <p>{t(basePath + "box1.item4")}.</p>
              <p>{t(basePath + "box1.item5")}</p>
              <Button href="https://t.me/BoostExWallet" type="primary">
                {t(basePath + "btn")}
              </Button>
            </Col>
          </Row>
        </div>
      </section>

      <section className="coins">
        <h1>{t(basePath + "title2")}</h1>
        <div className="items">
          <div className="box">
            <img
              src={require("../assets/img/icons/tether-usdt-logo.svg").default}
              alt=""
            />
          </div>
          <div className="box">
            <img
              src={require("../assets/img/icons/bitcoin-btc-logo.svg").default}
              alt=""
            />
          </div>
          <div className="box">
            <img
              src={require("../assets/img/icons/ethereum-eth-logo.svg").default}
              alt=""
            />
          </div>
          <div className="box">
            <img
              src={require("../assets/img/icons/tron-trx-logo.svg").default}
              alt=""
            />
          </div>
          <div className="box">
            <img
              src={require("../assets/img/icons/xrp-xrp-logo.svg").default}
              alt=""
            />
          </div>
          <div className="box">
            <img
              src={
                require("../assets/img/icons/usd-coin-usdc-logo.svg").default
              }
              alt=""
            />
          </div>
          <div className="box">
            <img
              src={require("../assets/img/icons/bnb-bnb-logo.svg").default}
              alt=""
            />
          </div>
          <div className="box">
            <img
              src={require("../assets/img/icons/toncoin-ton-logo.svg").default}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="other">
        <Row>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <div className="box">
              <h4>{t(basePath + "box2.title")}</h4>
              <p>{t(basePath + "box2.desc")}</p>
              <img
                src={require("../assets/img/planets/yellow_planet.png")}
                alt=""
              />
            </div>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <div className="box">
              <h4>{t(basePath + "box3.title")}</h4>
              <p>{t(basePath + "box3.desc")}</p>
              <img src={require("../assets/img/check3.png")} alt="" />
            </div>
          </Col>
        </Row>
      </section>

      <Footer />
    </div>
  );
};

export default Convertation;
