import React from "react";

const IconTimes = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="#ef4444"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17 2C8.716 2 2 8.716 2 17s6.716 15 15 15 15-6.716 15-15S25.284 2 17 2zm6.95 9.464-5.574 5.574 4.932 5.498a1 1 0 0 1-1.414 1.414l-4.932-5.498-5.498 5.498a1 1 0 0 1-1.414-1.414l5.574-5.574-4.932-5.498a1 1 0 0 1 1.414-1.414l4.932 5.498 5.498-5.498a1 1 0 0 1 1.414 1.414z"></path>
    </svg>
  );
};

export default IconTimes;
