import { Button, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  const basePath = "home.banner.";

  return (
    <section className="banner">
      <Row align="middle">
        <Col xl={14} lg={14} md={24} sm={24} xs={24}>
          <h1>{t(basePath + "title")}</h1>
          <p>{t(basePath + "desc")}</p>
          <div className="btn-group">
            <Button href="https://t.me/BoostExWallet" type="default">
              {t(basePath + "btn")}
            </Button>
          </div>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <img
            src={require("../../assets/img/main.png")}
            className="banner-img"
            alt=""
          />
        </Col>
      </Row>
    </section>
  );
};

export default Banner;
