import React from "react";
import Banner from "../components/Home/Banner";
import NavBar from "../components/NavBar";
import About from "../components/Home/About";
import Other from "../components/Home/Other";
import Footer from "../components/Footer";

const Main = () => {
  return (
    <div className="main">
      <NavBar />
      <Banner />
      <About />
      <Other />
      <Footer />
    </div>
  );
};

export default Main;
