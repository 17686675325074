export const TRANSLATIONS_EN = {
  navBar: {
    items: {
      item1: "Wallet",
      item2: "Road map",
      item3: "Card to card",
      item4: "Convertation",
      item5: "Balance",
      item6: "Passive income",
      item7: "Bills",
      item8: "My team",
    },

    doc: "Documents",
    social: "We're on social media:",
    support: "Supported by:",
    btn: "Start",
  },

  home: {
    banner: {
      title: "Financial freedom with the innovative BoostEx Wallet",
      desc: "Make your cryptocurrency dreams a reality.",
      btn: "Connect wallet",
    },

    about: {
      block1: {
        title: "One messenger – countless possibilities.",
        desc: "A multifunctional Telegram bot that allows you to easily and comfortably buy, sell, exchange, store, and earn on cryptocurrencies within your favorite messenger.",
        table: {
          head: {
            item1: "Cryptocurrency",
            item2: "Deposit",
            item3: "Withdrawal",
            item4: "Purchase",
            item5: "Sale",
            item6: "Convertation",
            item7: "Investing",
          },
        },
      },

      block2: {
        title: "Convenient. Secure.",
        desc: "The functionality of Google Authenticator will help you enhance the security of your funds and using the bot within the Telegram messenger.",
        title2: "Use cryptocurrency for everyday payments and financial transactions",
        desc2: "One of the main functions of BoostEx Wallet is to securely store and multiply popular cryptocurrencies: Bitcoin, Ethereum, USDT, USDC, TON, BNB, Ripple, Tron.",
        btn: "Start",
      },

      block3: {
        title: "Investment direction in one of the most convenient and functional messengers",
        desc: "Investment strategies 'Saturn' and 'Uran' will allow you to get profit for 3, 6, 9 and 12 months from 120 to 150% per year.",
        item1: {
          desc1: "From 60 to 120% per year",
          desc2: "Investing from 50 USDT",
          desc3: "Daily withdrawal of accumulated profit - Yes",
        },
        item2: {
          desc1: "From 90 to 150% per year",
          desc2: "Investing from 50 USDT",
          desc3: "Daily withdrawal of accumulated profit - No",
        },

        desc2: "* Withdrawal of the deposit body at the end of the tariff term",
        btn: "Read more about investment direction",
      },
    },

    other: {
      title: "Bills system",
      desc: "A convenient and fast way to send cryptocurrency between BoostEx Wallet users using Checks without commissions",
      btn: "Create a check",
    }
  },

  cardToCard: {
    title: "Card to card",
    desc: "Direct exchange of assets between users without intermediaries",
    btn: "Start",

    currencies: {
      title: "Buy cryptocurrency with any currency",
      item1: "Rubles",
      item2: "Dollars",
      item3: "Euros",
      item4: "Belarusian ruble",
      item5: "Tenge",
      item6: "Sum",
      item7: "Som",
      item8: "Somoni",
      item9: "Lira",
    },

    other: {
      item1: {
        title: "Cryptocurrency buying",
        desc: "Buying cryptocurrency, you do not need to pay a commission. The payment can be made using any convenient method and payment service.",
      },
      item2: {
        title: "Cryptocurrency selling",
        desc: "Selling cryptocurrency is a fast and convenient way to withdraw funds directly to your card or any other payment service.",
      },
      item3: {
        title: "Creating posters",
        desc: "Trade digital assets on your own terms by creating ads to buy and sell cryptocurrency. To create an announcement, you need to fill in the mandatory parameters and publish it.",
      },
    },
  },

  convertation: {
    title: "Convertation",
    desc: "A simplified way to exchange one cryptocurrency for another in BoostEx Wallet.",
    btn: "Convert",

    box1: {
      title: "How to convert cryptocurrency",
      item1: "1. Go to the 'Convert' section.",
      item2: "2. Select the coin you want to exchange.",
      item3: "3. Specify the cryptocurrency you want to receive in return.",
      item4: "4. Specify the amount of cryptocurrency to exchange.",
      item5: "5. Confirm the convertation.",
    },

    title2: "Convertation of trending tokens",

    box2: {
      title: "No commissions",
      desc: "The 'Convertation' section works without charging additional commissions, as they are already included in the exchange rate when converting.",
    },

    box3: {
      title: "Convertation history",
      desc: "Statistics of previously performed conversions can be found in the 'Convertation history' subsection",
    }
  },

  balance: {
    title: "Balance",
    desc: "View information about the amount of cryptocurrency and fiat funds stored in your BoostEx Wallet",
    btn: "Balance",
    title2: "The most popular cryptocurrencies are already in BoostEx Wallet",
    box1: {
      title: "How to deposit cryptocurrency",
      item1: "1. In the 'Balance' section, select 'Deposit'",
      item2: "2. Specify the cryptocurrency you want to deposit",
      item3: "3. send the cryptocurrency using Address or QR code",
      btn: "Refill BoostЕx Wallet",
    },
    box2: {
      title: "How to withdraw cryptocurrency",
      item1: "1. In the 'Balance' section, check out 'Withdrawal'",
      item2: "2. Specify the cryptocurrency you want to withdraw",
      item3: "3. Select the network for cryptocurrency withdrawal",
      item4: "4. Specify the address for cryptocurrency withdrawal",
      item5: "5. Specify the amount you wish to withdraw",
      btn: "Balance BoostЕx Wallet",
    },
  },

  passive: {
    title: "Spend your time doing what you like and earn money with BoostEx Wallet",
    desc: "A way to earn money in BoostEx Wallet that doesn't require your participation and daily activity.",
    btn: "Start",
    months: "months",

    block1: {
      title: "Investment direction 'Uran'",
      desc: "Allows you to accumulate and multiply your profits daily by reinvestment",
    },

    block2: {
      title: "Investment direction 'Saturn'",
      desc: "Allows you to accumulate and multiply your profit daily by reinvestment",
    },
  },

  checks: {
    title: "Checks",
    desc: "You can use 'Checks' to send funds without fees to any BoostEx Wallet user. This is a convenient and fast way to send cryptocurrency without entering long addresses and waiting for transaction confirmation in the blockchain.",
    btn: "Create a check",

    block1: {
      title: "How to create a check",
      item1: "1. Go to the Checks section",
      item2: "2. Click 'Create Check'",
      item3: "3. Select one or more cryptocurrencies to pay for the 'Check'",
      item4: "4. Click Next ➡️ Done ✅.",
    },

    block2: {
      title: "After creating a check, you can:",
      item1: "- Send the check as a link;",
      item2: "- Generate a QR code to pay for the check;",
      item3: "- Add a description;",
      item4: "- Specify the number of payments for the check;",
      item5: "- Specify the minimum amount to pay;",
      item6: "- Delete a previously created check.",
    },

    block3: {
      title: "Check History",
      desc: "Your check history is listed in the 'My Checks' subsection. In this subsection you can see your Active and Closed checks. To view information on these checks - click on it and view statistics",
    },

    block4: {
      title: "Commissions",
      desc: "The Checks section operates without fees for both transfers and receipts.",
    },
  },

  team: {
    title: "My team",
    desc: "Invite partners to BoostEx and earn cryptocurrency",
    btn: "Start",

    box1: {
      title: "Types of referral rewards:",
      item1: "1. Referral per trade: 25% of the commission of the trades created by your partners.",
      item2: "2. Referral for purchase of 'Uran' and 'Saturn' tariffs.",
    },

    table: {
      title1: "Referral fees for the sale of Uran plans",
      desc1: "To get a new level of referral rewards - it is necessary to invite partners with Investment Plan 'Uran' in level 1 for the amount from 100 000$, etc.",
      title2: "Referral rewards for the sale of Saturn plans",
      desc2: "To get a new level of referral rewards - it is necessary to invite partners with Investment Plan 'Saturn' in level “1” for the amount from 50 000$, etc.",

      level: "level",
      turnover: "Turnover",
      months1: "months",
      months2: "months",

      text: {
        item1: "Turnover",
        item2: "to get a",
        item3: "new level",
      },

      name: {
        item1: "Оrbit",
        item2: "Ophelia",
        item3: "Belinda",
        item4: "Titania",
        item5: "Aubreon",
        item6: "Tefia",
        item7: "Diona",
        item8: "Rhea",
        item9: "Тitan",
      }
    },

    info: {
      text1: "The referral link is intended for inviting your partners to",
      text2: "and further receiving bonuses to your account.",
    }
  },

  faq: {
    title: "Frequently asked questions",
    item1: {
      title: "What should I do if my Telegram account has been hacked?",
      desc: "If your Telegram account is hacked or lost, you can contact support and specify the phone number/username that was previously attached to your account, then BoostEx Wallet support will show you the next steps to restore access to your wallet.",
    },
    item2: {
      title: "Where are my partners displayed?",
      desc: "The number of tariff plans for 1 account is unlimited.",
    },
    item3: {
      title: "How many tariff plans can be opened on 1 account?",
      desc: "The number of tariff plans for 1 account is unlimited.",
    },
    item4: {
      title: "If my account is hacked, can I withdraw funds without my knowledge?",
      desc: "2FA - all withdrawal transactions must be confirmed with an authenticator code, thanks to this feature, no one can withdraw funds from your BooostEx Wallet without your knowledge.",
    },
    item5: {
      title: "What is 2FA with BoostEx Wallet?",
      desc: "2FA with Google Authenticator is a vital security tool for organizations to protect their data and users in a cybersecurity environment filled with more and more sophisticated cyberattacks.",
    },
    item6: {
      title: "What are the fees to withdraw cryptocurrencies?",
    },
    item7: {
      title: "What is the minimum amount to deposit?",
    },
    item8: {
      title: "What is the minimum amount for withdrawal??",
    },
    item9: {
      title: "What is the commission for withdrawing funds to third-party wallets?",
    },
    item10: {
      title: "What are the regulations for withdrawal of funds?",
      desc: "Transactions are processed from 5 to 30 minutes, depending on the network load. Transactions in coins such as BTC and ETH take longer to process.",
    },
    item11: {
      title: "What is the minimum amount to invest in a plan?",
      desc: "For investing in Uran and Saturn plans, the minimum investment amount is $50 equivalent to any cryptocurrency.",
    },
    item12: {
      title: "Can I close my plan early?",
      desc: "BoostEx Wallet does not have an early plan closure feature.",
    },
    item13: {
      title: "After the tariff plan is terminated, where do my funds go?",
      desc: "After completion of the tariff plans Uran and Saturn, the funds are credited to the account of the coin in which the tariff was previously opened and are displayed in the Balance section.",
    },
  },
};
