import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Switch } from "antd";
import { IconClose, IconMenu } from "../utils/Icons/index";

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const basePath = "navBar.";

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [isRussian, setIsRussian] = useState(
    localStorage.getItem("i18nextLng") === "ru-RU"
  );

  useEffect(() => {
    const activeLang = isRussian ? "ru-RU" : "en-US";
    i18n.changeLanguage(activeLang);
    localStorage.setItem("i18nextLng", activeLang);
  }, [isRussian, i18n]);

  const handleLanguageSwitch = (checked) => {
    setIsRussian(checked);
  };

  const handleOpenMobile = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <nav className="nav index">
      <div className="nav-wrapper">
        <a className="nav-logo" href="/">
          <img src={require("../assets/img/logo.svg").default} alt="" />
        </a>

        <div className="nav-btns">
          <ul>
            <li>{t(basePath + "items.item1")}</li>
            <li>
              <a href="/">{t(basePath + "items.item2")}</a>
            </li>
            <li>
              <a href="/faq">FAQ</a>
            </li>
          </ul>

          <Button href="https://t.me/BoostExWallet" type="primary">
            {t(basePath + "btn")}
          </Button>
          <div className="language-switch">
            <Switch
              checkedChildren="RU"
              unCheckedChildren="EN"
              checked={isRussian}
              onChange={handleLanguageSwitch}
            />
          </div>

          <div className="toggle" onClick={handleOpenMobile}>
            {!showMobileMenu ? (
              <IconMenu width="35" height="35" />
            ) : (
              <IconClose width="35" height="35" />
            )}
          </div>

          {/* Hover menu */}
          <div className={`nav-dropdown  ${showMobileMenu && "active"}`}>
            <section>
              <div className="menu-content">
                <img src={require("../assets/img/main.png")} alt="Logo" />
                <div className="menu">
                  <a href="/faq" className="hidden-md">
                    FAQ
                  </a>
                  <a href="/card-to-card">{t(basePath + "items.item3")}</a>
                  <a href="/convertation">{t(basePath + "items.item4")}</a>
                  <a href="/balance">{t(basePath + "items.item5")}</a>
                  <a href="/passive-income">{t(basePath + "items.item6")}</a>
                  <a href="/checks">{t(basePath + "items.item7")}</a>
                  <a href="/team">{t(basePath + "items.item8")}</a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
