import { Col, Collapse, Row } from "antd";
import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const FAQs = () => {
  const { t } = useTranslation();
  const basePath = "faq.";

  const items = [
    {
      key: "1",
      label: t(basePath + "item1.title"),
      children: <p>{t(basePath + "item1.desc")}</p>,
    },
    {
      key: "2",
      label: t(basePath + "item2.title"),
      children: <p>{t(basePath + "item2.desc")}</p>,
    },
    {
      key: "3",
      label: t(basePath + "item3.title"),
      children: <p>{t(basePath + "item3.desc")}</p>,
    },
    {
      key: "4",
      label: t(basePath + "item4.title"),
      children: <p>{t(basePath + "item4.desc")}</p>,
    },
    {
      key: "5",
      label: t(basePath + "item5.title"),
      children: <p>{t(basePath + "item5.desc")}</p>,
    },
    {
      key: "6",
      label: t(basePath + "item6.title"),
      children: (
        <p>
          Tether - 2,5 USDT;
          <br />
          Tron - 23 TRX;
          <br />
          Bitcoin - 0,001765 BTC;
          <br />
          Ethereum - 0,0093 ETH;
          <br />
          Ripple - 6,7 XRP;
          <br />
          TON - 1,08 TON;
          <br />
          USDC - 17,5 USDC;
          <br />
          BNB - 0.021 BNB.
        </p>
      ),
    },
    {
      key: "7",
      label: t(basePath + "item7.title"),
      children: (
        <p>
          Tether TRC20 - 5 USDT;
          <br />
          Tether ERC20 - 20 USDT;
          <br />
          Tron - 102 TRX;
          <br />
          Bitcoin - 0,002 BTC;
          <br />
          Ethereum - 0,05 ETH;
          <br />
          USD Coin - 150 USDC;
          <br />
          Binance Smart Chain - 0.05 BNB;
          <br />
          TON Coin - 6 TON;
          <br />
          Ripple - 20 XRP.
        </p>
      ),
    },
    {
      key: "8",
      label: t(basePath + "item8.title"),
      children: (
        <p>
          Tether TRC20 - 10 USDT;
          <br />
          Tether ERC20 - 45 USDT;
          <br />
          Tron - 115 TRX;
          <br />
          Bitcoin - 0,00385 BTC;
          <br />
          Ethereum - 0,069 ETH;
          <br />
          USD Coin - 50 USDC;
          <br />
          Binance Smart Chain - 0.085 BNB;
          <br />
          TON Coin - 6 TON;
          <br />
          Ripple - 100 XRP.
        </p>
      ),
    },
    {
      key: "9",
      label: t(basePath + "item9.title"),
      children: (
        <p>
          Tether - 2,5 USDT;
          <br />
          Bitcoin - 0,001765 BTC;
          <br />
          Ethereum - 0,0093 ETH;
          <br />
          USDC - 17,5 USDC;
          <br />
          TON - 1,08 TON;
          <br />
          BNB - 0.021 BNB;
          <br />
          Tron - 23 TRX;
          <br />
          Ripple - 6,7 XRP.
        </p>
      ),
    },
    {
      key: "10",
      label: t(basePath + "item10.title"),
      children: <p>{t(basePath + "item10.desc")}</p>,
    },
    {
      key: "11",
      label: t(basePath + "item11.title"),
      children: <p>{t(basePath + "item11.desc")}</p>,
    },
    {
      key: "12",
      label: t(basePath + "item12.title"),
      children: <p>{t(basePath + "item12.desc")}</p>,
    },
    {
      key: "13",
      label: t(basePath + "item13.title"),
      children: <p>{t(basePath + "item13.desc")}</p>,
    },
  ];

  return (
    <div className="faq">
      <NavBar />

      <section className="banner">
        <Row align="middle">
          <Col xl={10} lg={10} md={24} sm={24} xs={24}>
            <img src={require("../assets/img/binocle.png")} alt="" />
          </Col>
          <Col xl={14} lg={14} md={24} sm={24} xs={24}>
            <h1>{t(basePath + "title")}</h1>
          </Col>
        </Row>
      </section>

      <section>
        <Collapse items={items} expandIconPosition="end" />
      </section>

      <Footer />
    </div>
  );
};

export default FAQs;
