import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Main from "../pages/Main";
import CardToCard from "../pages/CardToCard";
import Convertation from "../pages/Convertation";
import Balance from "../pages/Balance";
import Passive from "../pages/Passive";
import Checks from "../pages/Checks";
import FAQs from "../pages/FAQs";
import Team from "../pages/Team";

function Routes() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Main} exact />
          <Route path="/card-to-card" component={CardToCard} />
          <Route path="/convertation" component={Convertation} />
          <Route path="/balance" component={Balance} />
          <Route path="/passive-income" component={Passive} />
          <Route path="/checks" component={Checks} />
          <Route path="/faq" component={FAQs} />
          <Route path="/team" component={Team} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routes;
