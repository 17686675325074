import { Button, Col, Row } from "antd";
import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Passive = () => {
  const { t } = useTranslation();
  const basePath = "passive.";

  return (
    <div className="passive">
      <NavBar />

      <section className="banner">
        <Row align="middle">
          <Col xl={14} lg={14} md={24} sm={24} xs={24}>
            <h1>{t(basePath + "title")}</h1>
            <p>{t(basePath + "desc")}</p>
            <div className="btn-group">
              <Button href="https://t.me/BoostExWallet" type="primary">
                {t(basePath + "btn")}
              </Button>
            </div>
          </Col>
          <Col xl={10} lg={10} md={24} sm={24} xs={24}>
            <img src={require("../assets/img/fiolet.png")} alt="" />
          </Col>
        </Row>
      </section>

      <section className="info">
        <div className="box">
          <h2>{t(basePath + "block1.title")}</h2>
          <p>{t(basePath + "block1.desc")}</p>
          <div className="items">
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/bitcoin-btc-logo.svg").default
                }
                alt=""
              />
              <h4>Bitcoin</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+15%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+30%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+60%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/tether-usdt-logo.svg").default
                }
                alt=""
              />
              <h4>USDT</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+30%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+60%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+90%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+120%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/usd-coin-usdc-logo.svg").default
                }
                alt=""
              />
              <h4>USDС</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+30%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+60%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+90%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+120%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/ethereum-eth-logo.svg").default
                }
                alt=""
              />
              <h4>Ethereum</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+15%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+30%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+60%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/toncoin-ton-logo.svg").default
                }
                alt=""
              />
              <h4>TON</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+15%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+30%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+60%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={require("../assets/img/icons/tron-trx-logo.svg").default}
                alt=""
              />
              <h4>Tron</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+15%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+30%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+60%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={require("../assets/img/icons/bnb-bnb-logo.svg").default}
                alt=""
              />
              <h4>BNB</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+15%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+30%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+60%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={require("../assets/img/icons/xrp-xrp-logo.svg").default}
                alt=""
              />
              <h4>Ripple</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+15%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+30%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+60%</span>
                </li>
              </ul>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <h2>{t(basePath + "block2.title")}</h2>
          <p>{t(basePath + "block2.desc")}</p>

          <div className="items">
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/bitcoin-btc-logo.svg").default
                }
                alt=""
              />
              <h4>Bitcoin</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+22%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+67%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+90%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/tether-usdt-logo.svg").default
                }
                alt=""
              />
              <h4>USDT</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+37%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+75%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+112%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+150%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/usd-coin-usdc-logo.svg").default
                }
                alt=""
              />
              <h4>USDС</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+37%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+75%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+112%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+150%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/ethereum-eth-logo.svg").default
                }
                alt=""
              />
              <h4>Ethereum</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+22%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+67%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+90%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={
                  require("../assets/img/icons/toncoin-ton-logo.svg").default
                }
                alt=""
              />
              <h4>TON</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+22%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+67%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+90%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={require("../assets/img/icons/tron-trx-logo.svg").default}
                alt=""
              />
              <h4>Tron</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+22%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+67%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+90%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={require("../assets/img/icons/bnb-bnb-logo.svg").default}
                alt=""
              />
              <h4>BNB</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+22%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+67%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+90%</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <img
                src={require("../assets/img/icons/xrp-xrp-logo.svg").default}
                alt=""
              />
              <h4>Ripple</h4>
              <ul>
                <li>
                  3 {t(basePath + "months")} <span>+22%</span>
                </li>
                <li>
                  6 {t(basePath + "months")} <span>+45%</span>
                </li>
                <li>
                  9 {t(basePath + "months")} <span>+67%</span>
                </li>
                <li>
                  12 {t(basePath + "months")} <span>+90%</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="btn">
            <Button href="https://t.me/BoostExWallet" type="primary">
              {t(basePath + "btn")}
            </Button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Passive;
