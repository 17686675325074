import React from "react";

const IconMenu = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path
        fill="#0500ff"
        d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"
      ></path>
    </svg>
  );
};

export default IconMenu;
