import { Button, Col, Row } from "antd";
import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const CardToCard = () => {
  const { t } = useTranslation();
  const basePath = "cardToCard.";

  return (
    <div className="card">
      <NavBar />

      <section className="banner">
        <Row align="middle">
          <Col xl={14} lg={14} md={14} sm={24} xs={24}>
            <h1>{t(basePath + "title")}</h1>
            <p>{t(basePath + "desc")}</p>
            <div className="btn-group">
              <Button href="https://t.me/BoostExWallet" type="primary">
                {t(basePath + "btn")}
              </Button>
            </div>
          </Col>
          <Col xl={10} lg={10} md={10} sm={24} xs={24}>
            <img src={require("../assets/img/triple_planet.png")} alt="" />
          </Col>
        </Row>
      </section>

      <section className="currencies">
        <Row align="middle" justify="space-between">
          <Col xl={10} lg={10} md={10} sm={24} xs={24}>
            <img
              src={require("../assets/img/hi.png")}
              className="image"
              alt=""
            />
          </Col>
          <Col xl={10} lg={10} md={14} sm={24} xs={24}>
            <div className="box">
              <h4>{t(basePath + "currencies.title")}</h4>
              <ul>
                <li>
                  <img
                    src={require("../assets/img/currencies/RUB.png")}
                    alt=""
                  />
                  <p>{t(basePath + "currencies.item1")}</p>
                </li>
                <li>
                  <img
                    src={require("../assets/img/currencies/USD.png")}
                    alt=""
                  />
                  <p>{t(basePath + "currencies.item2")}</p>
                </li>
                <li>
                  <img
                    src={require("../assets/img/currencies/EUR.png")}
                    alt=""
                  />
                  <p>{t(basePath + "currencies.item3")}</p>
                </li>
                <li>
                  <img
                    src={require("../assets/img/currencies/BYN.png")}
                    alt=""
                  />
                  <p>{t(basePath + "currencies.item4")}</p>
                </li>
                <li>
                  <img
                    src={require("../assets/img/currencies/KZT.png")}
                    alt=""
                  />
                  <p>{t(basePath + "currencies.item5")}</p>
                </li>
                <li>
                  <img
                    src={require("../assets/img/currencies/UZS.png")}
                    alt=""
                  />
                  <p>{t(basePath + "currencies.item6")}</p>
                </li>
                <li>
                  <img
                    src={require("../assets/img/currencies/KGS.png")}
                    alt=""
                  />
                  <p>{t(basePath + "currencies.item7")}</p>
                </li>
                <li>
                  <img
                    src={require("../assets/img/currencies/TJS.png")}
                    alt=""
                  />
                  <p>{t(basePath + "currencies.item8")}</p>
                </li>
                <li>
                  <img
                    src={require("../assets/img/currencies/TRY.png")}
                    alt=""
                  />
                  <p>{t(basePath + "currencies.item9")}</p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </section>

      <section className="other">
        <Row>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className="box">
              <h3>{t(basePath + "other.item1.title")}</h3>
              <p>{t(basePath + "other.item1.desc")}</p>
              <img
                src={require("../assets/img/planets/blue_planet.png")}
                alt=""
              />
            </div>
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className="box">
              <h3>{t(basePath + "other.item2.title")}</h3>
              <p>{t(basePath + "other.item2.desc")}</p>
              <img
                src={require("../assets/img/planets/yellow_planet.png")}
                alt=""
              />
            </div>
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className="box">
              <h3>{t(basePath + "other.item3.title")}</h3>
              <p>{t(basePath + "other.item3.desc")}</p>
              <img
                src={require("../assets/img/planets/orange_planet.png")}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </section>

      <Footer />
    </div>
  );
};

export default CardToCard;
