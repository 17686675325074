export const TRANSLATIONS_RU = {
  navBar: {
    items: {
      item1: "Кошелек",
      item2: "Дорожная карта",
      item3: "С карты на карту",
      item4: "Конвертация",
      item5: "Баланс",
      item6: "Пассивный заработок",
      item7: "Чеки",
      item8: "Моя команда",
    },

    doc: "Документы",
    social: "Мы в социальных сетях:",
    support: "При поддержке:",
    btn: "Начать",
  },

  home: {
    banner: {
      title: "Финансовая свобода с инновационным кошельком BoostEx Wallet",
      desc: "Сделайте свои криптовалютные мечты реальностью.",
      btn: "Подключить кошелек",
    },

    about: {
      block1: {
        title: "Один мессенджер - множество возможностей",
        desc: "Многофункциональный Telegram-бот, позволяющий легко и комфортно покупать, продавать, обменивать, хранить и зарабатывать на криптовалютах внутри привычного мессенджера.",
        table: {
          head: {
            item1: "Криптовалюта",
            item2: "Ввод",
            item3: "Вывод",
            item4: "Покупка",
            item5: "Продажа",
            item6: "Конвертация",
            item7: "Инвестирование",
          },
        },
      },

      block2: {
        title: "Удобно. Надежно.",
        desc: "Функционал Google Authenticator поможет вам повысить уровень безопасности сохранности средств и использования бота внутри мессенджер телеграмм.",
        title2: "Используйте криптовалюту для повседневных платежей и финансовых сделок",
        desc2: "Одна из главных функций BoostEx Wallet – это безопасное хранение и приумножение популярных криптовалют: Tether, Bitcoin, Ethereum, Tron и Ripple.",
        btn: "Начать",
      },

      block3: {
        title: "Инвестиционное направление в одном из самых удобных и функциональных мессенджеров",
        desc: "Инвестиционные стратегии 'Saturn' и 'Uran' позволят Вам получать прибыль на протяжении 3, 6, 9 и 12 месяцев от 120 до 150% годовых.",
        item1: {
          desc1: "От 60 до 120 % годовых",
          desc2: "Инвестирование от 50 USDT",
          desc3: "Ежедневный вывод накопленной прибыли - Да",
        },
        item2: {
          desc1: "От 90 до 150 % годовых",
          desc2: "Инвестирование от 50 USDT",
          desc3: "Ежедневный вывод накопленной прибыли - Нет",
        },

        desc2: "* Вывод тела депозита в конце срока работы тарифа",
        btn: "Подробнее о инвестиционном направлении",
      },
    },

    other: {
      title: "Система Чеков",
      desc: "Удобный и быстрый способ отправки криптовалюты между пользователями BoostEx Wallet с помощью Чеков без комиссий",
      btn: "Создать чек",
    }
  },

  cardToCard: {
    title: "С карты на карту",
    desc: "Прямой обмен активами между пользователями без посредников",
    btn: "Начать",

    currencies: {
      title: "Покупайте криптовалюту с помощью любой валюты",
      item1: "Рубли",
      item2: "Доллары",
      item3: "Эвро",
      item4: "Белорусский рубль",
      item5: "Тенге",
      item6: "Сум",
      item7: "Сом",
      item8: "Сомони",
      item9: "Лира",
    },

    other: {
      item1: {
        title: "Покупка криптовалюты",
        desc: "Совершая покупку криптовалюты, Вам не нужно платить комиссию. При этом оплату можно провести с помощью любого удобного метода и платежного сервиса.",
      },
      item2: {
        title: "Продажа криптовалюты",
        desc: "Продажа криптовалюты — это быстрый и удобный способ вывода средств прямо на карту или любой другой платежный сервис.",
      },
      item3: {
        title: "Создание афиш",
        desc: "Торгуйте цифровыми активами на своих условиях, создавая объявления на покупку и продажу криптовалюты. Для создания обьявления, необходимо заполнить обязательные параметры и опубликовать его.",
      },
    },
  },

  convertation: {
    title: "Конвертация",
    desc: "Упрощенный способ обмена одной криптовалюты на другую в BoostEx Wallet.",
    btn: "Конвертировать",

    box1: {
      title: "Как конвертировать криптовалюту",
      item1: "1. Перейдите в раздел 'Конвертация'.",
      item2: "2. Выберите монету, которую хотите обменять.",
      item3: "3. Укажите криптовалюту, которую хотите получить взамен.",
      item4: "4. Укажите объем криптовалюты для обмена.",
      item5: "5. Подтвердите конвертацию.",
    },

    title2: "Конвертация трендовых токенов",

    box2: {
      title: "Отсутствие комиссий",
      desc: "Раздел 'Конвертации' работает без взимания дополнительных комиссий, так как они они уже включены в курс обмена при конвертации.",
    },

    box3: {
      title: "История конвертаций",
      desc: "Статистика ранее проделанных конвертаций находится в подразделе 'История конвертаций'.",
    }
  },

  balance: {
    title: "Баланс",
    desc: "Просматривайте информацию о сумме средств в криптовалюте и фиате, которая хранится в Вашем кошельке BoostEx Wallet",
    btn: "Баланс",
    title2: "Самые популярные криптовалюты уже в BoostEx Wallet",
    box1: {
      title: "Как внести криптовалюту",
      item1: "1. В разделе 'Баланс' выберите 'Пополнение'",
      item2: "2. Укажите криптовалюту, которую хотите внести",
      item3: "3. Отправьте криптовалюту с помощью Адреса или QR-кода",
      btn: "Пополнить Boostex Wallet",
    },
    box2: {
      title: "Как вывести криптовалюту",
      item1: "1. В разделе 'Баланс' выверите 'Вывод'",
      item2: "2. Укажите криптовалюту, которую хотите вывести",
      item3: "3. Выберите сеть для вывода криптовалюты",
      item4: "4. Укажите адрес для вывода криптовалюты",
      item5: "5. Укажите сумму, которую желаете вывести",
      btn: "Баланс BoostЕx Wallet",
    },
  },

  passive: {
    title: "Тратьте время на то, что нравится, и получайте прибыль в BoostEx Wallet",
    desc: "Способ заработка в BoostEx Wallet, который не требует от Вас участия и ежедневной активности.",
    btn: "Начать",
    months: "месяца",

    block1: {
      title: "Инвестиционное направление 'Uran'",
      desc: "Позволит ежедневно накапливать и приумножать Вашу прибыль путем реинвестирования",
    },

    block2: {
      title: "Инвестиционное направление 'Saturn'",
      desc: "Позволит ежедневно накапливать и приумножать Вашу прибыль путем реинвестирования",
    },
  },

  checks: {
    title: "Чеки",
    desc: "С помощью 'Чеков' можно отправить средства без комиссии любому пользователю BoostEx Wallet. Это удобный и быстрый способ отправки криптовалюты без ввода длинных адресов и ожидания подтверждения транзакций в блокчейне.",
    btn: "Создать чек",

    block1: {
      title: "Как создать чек",
      item1: "1. Перейдите в раздел 'Чеки'",
      item2: "2. Нажмите 'Создать чек'",
      item3: "3. Выберите одну или несколько криптовалют для оплаты 'Чека'",
      item4: "4. Нажмите Далее ➡️ Готово ✅.",
    },

    block2: {
      title: "Покупка криптовалюты",
      item1: "- Отправить чек в виде ссылки",
      item2: "- Сгенерировать QR-код для оплаты чека;",
      item3: "- Добавить описание;",
      item4: "- Указать количество оплат для чека;",
      item5: "- Указать минимальную сумму для оплаты;",
      item6: "- Удалить ранее созданный чек.",
    },

    block3: {
      title: "История чеков",
      desc: "История Ваших чеков указана в подразделе 'Мои чеки'. В данном подразделе Вы можете увидеть Ваши Активные и Закрытие чеки. Для просмотра информации по данным чекам - нажмите на него и просмотрите статистику.",
    },

    block4: {
      title: "Комиссии",
      desc: "Раздел 'Чеки' работает без взимания комиссий, как для переводов, так и для получения средств.",
    },
  },

  team: {
    title: "Моя команда",
    desc: "Приглашайте партнеров в BoostEx и зарабатывайте криптовалюту",
    btn: "Начать",

    box1: {
      title: "Виды реферальных вознаграждений:",
      item1: "1. Реферальные за торговлю: 25% от комиссии сделок, созданных Вашими партнерами.",
      item2: "2. Реферальные за покупку тарифов 'Uran' и 'Saturn'.",
    },

    table: {
      title1: "Реферальные вознаграждения за продажу планов 'Uran'",
      desc1: "Для получения нового уровня реферальных вознаграждений - необходимо пригласить партнеров с Инвестиционным планом 'Uran' в 1 уровень на сумму от 100 000$ и т.д.",
      title2: "Реферальные вознаграждения за продажу планов 'Saturn'",
      desc2: "Для получения нового уровня реферальных вознаграждений - необходимо пригласить партнеров с Инвестиционным планом 'Saturn' в 1 уровень на сумму от 50 000$ и т.д.",

      level: "уровень",
      turnover: "Оборот",
      months1: "месяца",
      months2: "месяцев",

      text: {
        item1: "Товарооборот",
        item2: "для получения",
        item3: "нового уровня",
      },

      name: {
        item1: "Орбита",
        item2: "Офелия",
        item3: "Белинда",
        item4: "Титания",
        item5: "Обреон",
        item6: "Тефия",
        item7: "Диона",
        item8: "Рея",
        item9: "Титан",
      }
    },

    info: {
      text1: "Реферальная ссылка предназначена для приглашения Ваших партнеров в",
      text2: "и дальнейшего получения бонусов на Ваш счет.",
    }
  },

  faq: {
    title: "Часто задаваемые вопросы",
    item1: {
      title: "Что делать если мой аккаунт Telegram взломали?",
      desc: " В случае взлома или утери доступа к Вашему аккаунту Telegram, Вы можете обратиться в поддержку и указать номер телефона/username, который ранее был прикреплен к Вашему аккаунту, затем поддержка BoostEx Wallet укажет Вам дальнейшие действия для восстановления доступа к Вашему кошельку.",
    },
    item2: {
      title: "Где отображаются Мои партнеры?",
      desc: "Количество тарифных планов для 1 аккаунта не ограничено.",
    },
    item3: {
      title: "Сколько тарифных планов можно открыть на 1 аккаунте?",
      desc: "Количество тарифных планов для 1 аккаунта не ограничено.",
    },
    item4: {
      title: "Если мой аккаунт взломали, оттуда могут вывести средства без моего ведома?",
      desc: "2FA - все операции для вывода средств необходимо подтвердить кодом с аутентификатора, благодаря данной функции, без Вашего ведома никто не сможет вывести средства с Вашего кошелька BooostEx Wallet.",
    },
    item5: {
      title: "Что такое 2FA в BoostEx Wallet?",
      desc: "2FA с помощью Google Authenticator - это жизненно важный инструмент безопасности для организаций, позволяющий защитить свои данные и пользователей в условиях кибербезопасности, наполненной большим количеством все более изощренных кибератак.",
    },
    item6: {
      title: "Какие комиссии для вывода криптовалют?",
    },
    item7: {
      title: "Какая минимальная сумма для ввода средств?",
    },
    item8: {
      title: "Какая минимальная сумма для вывода средств?",
    },
    item9: {
      title: "Какая комиссия для вывода средств на сторонние кошельки?",
    },
    item10: {
      title: "Какой регламент для вывода средств?",
      desc: "Транзакции обрабатываются от 5 до 30 минут, в зависимости от нагрузки сети. Транзакции в таких монетах, как BTC и ETH обрабатываются дольше.",
    },
    item11: {
      title: "Какая минимальная сумма для инвестирования в тарифный план?",
      desc: "Для инвестирования в тарифные планы Uran и Saturn, минимальная сумма инвестирования составляет 50$ в эквиваленте к любой криптовалюте.",
    },
    item12: {
      title: "Можно ли досрочно закрыть тарифный план?",
      desc: "В BoostEx Wallet не предусмотрена функция досрочного закрытия тарифного плана.",
    },
    item13: {
      title: "После завершения работы тарифного плана, куда деваются мои средства?",
      desc: "После завершения работы тарифных планов Uran и Saturn, средства зачисляются на счет монеты, в которой ранее был открыт тариф и отображаются в разделе Баланс.",
    },

  },
};
