import { Button, Col, Row } from "antd";
import React from "react";
import { IconCheck, IconTimes } from "../../utils/Icons/index";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  const basePath = "home.about.";

  return (
    <>
      <section className="about">
        <div className="content">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <h1>{t(basePath + "block1.title")}</h1>
              <p>{t(basePath + "block1.desc")}</p>
              <div className="table">
                <div className="table-header">
                  <p>{t(basePath + "block1.table.head.item1")}</p>
                  <div className="items">
                    <div className="item">
                      {t(basePath + "block1.table.head.item2")}
                    </div>
                    <div className="item">
                      {t(basePath + "block1.table.head.item3")}
                    </div>
                    <div className="item">
                      {t(basePath + "block1.table.head.item4")}
                    </div>
                    <div className="item">
                      {t(basePath + "block1.table.head.item5")}
                    </div>
                    <div className="item">
                      {t(basePath + "block1.table.head.item6")}
                    </div>
                    <div className="item">
                      {t(basePath + "block1.table.head.item7")}
                    </div>
                  </div>
                </div>
                <div className="table-content">
                  <ul>
                    <li>
                      <div className="crypto">
                        <img
                          src={
                            require("../../assets/img/icons/tether-usdt-logo.svg")
                              .default
                          }
                          alt=""
                        />
                        <span>USDT-ERC20 / TRC20</span>
                      </div>
                      <p>
                        <span>
                          <s>{t(basePath + "block1.table.head.item2")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item3")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item4")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item5")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item6")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item7")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                      </p>
                    </li>
                    <li>
                      <div className="crypto">
                        <img
                          src={
                            require("../../assets/img/icons/bitcoin-btc-logo.svg")
                              .default
                          }
                          alt=""
                        />
                        <span>Bitcoin</span>
                      </div>
                      <p>
                        <span>
                          <s>{t(basePath + "block1.table.head.item2")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item3")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item4")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item5")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item6")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item7")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                      </p>
                    </li>
                    <li>
                      <div className="crypto">
                        <img
                          src={
                            require("../../assets/img/icons/ethereum-eth-logo.svg")
                              .default
                          }
                          alt=""
                        />
                        <span>Ethereum</span>
                      </div>
                      <p>
                        <span>
                          <s>{t(basePath + "block1.table.head.item2")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item3")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item4")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item5")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item6")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item7")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                      </p>
                    </li>
                    <li>
                      <div className="crypto">
                        <img
                          src={
                            require("../../assets/img/icons/tron-trx-logo.svg")
                              .default
                          }
                          alt=""
                        />
                        <span>Tron</span>
                      </div>
                      <p>
                        <span>
                          <s>{t(basePath + "block1.table.head.item2")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item3")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item4")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item5")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item6")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item7")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                      </p>
                    </li>
                    <li>
                      <div className="crypto">
                        <img
                          src={
                            require("../../assets/img/icons/xrp-xrp-logo.svg")
                              .default
                          }
                          alt=""
                        />
                        <span>Ripple</span>
                      </div>
                      <p>
                        <span>
                          <s>{t(basePath + "block1.table.head.item2")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item3")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item4")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item5")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item6")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item7")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                      </p>
                    </li>
                    <li>
                      <div className="crypto">
                        <img
                          src={
                            require("../../assets/img/icons/usd-coin-usdc-logo.svg")
                              .default
                          }
                          alt=""
                        />
                        <span>USDС</span>
                      </div>
                      <p>
                        <span>
                          <s>{t(basePath + "block1.table.head.item2")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item3")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item4")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item5")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item6")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item7")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                      </p>
                    </li>
                    <li>
                      <div className="crypto">
                        <img
                          src={
                            require("../../assets/img/icons/bnb-bnb-logo.svg")
                              .default
                          }
                          alt=""
                        />
                        <span>BNB</span>
                      </div>
                      <p>
                        <span>
                          <s>{t(basePath + "block1.table.head.item2")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item3")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item4")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item5")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item6")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item7")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                      </p>
                    </li>
                    <li>
                      <div className="crypto">
                        <img
                          src={
                            require("../../assets/img/icons/toncoin-ton-logo.svg")
                              .default
                          }
                          alt=""
                        />
                        <span>TON</span>
                      </div>
                      <p>
                        <span>
                          <s>{t(basePath + "block1.table.head.item2")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item3")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item4")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item5")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item6")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                        <span>
                          <s>{t(basePath + "block1.table.head.item7")}</s>
                          <IconCheck width="20" height="20" fill="#0500FF" />
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="about v2">
        <div className="content">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <h1>{t(basePath + "block2.title")}</h1>
              <p>{t(basePath + "block2.desc")}</p>
            </Col>
          </Row>
          <div className="box">
            <Row align="middle" justify="space-between">
              <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                <h2>{t(basePath + "block2.title2")}</h2>
                <p>{t(basePath + "block2.desc2")}</p>
                <Button
                  href="https://t.me/BoostExWallet"
                  type="default"
                  className="xs"
                >
                  {t(basePath + "block2.btn")}
                </Button>
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <img src={require("../../assets/img/rocket.png")} alt="" />
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <section className="about v3">
        <div className="content">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <h1>{t(basePath + "block3.title")}</h1>
              <p>{t(basePath + "block3.desc")}</p>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <div className="box">
                <h4>
                  <span>
                    "Uran"
                    <img
                      src={require("../../assets/img/planets/blue_planet.png")}
                      alt=""
                    />
                  </span>
                </h4>
                <div className="items">
                  <p>{t(basePath + "block3.item1.desc1")}</p>
                  <p>{t(basePath + "block3.item1.desc2")}</p>
                  <p>
                    {t(basePath + "block3.item1.desc3")}
                    <IconCheck width="20" height="20" fill="#22c55e" />
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <div className="box">
                <h4>
                  <span>
                    "Saturn"
                    <img
                      src={require("../../assets/img/planets/yellow_planet.png")}
                      alt=""
                    />
                  </span>
                </h4>
                <div className="items">
                  <p>{t(basePath + "block3.item2.desc1")}</p>
                  <p>{t(basePath + "block3.item2.desc2")}</p>
                  <p>
                    {t(basePath + "block3.item2.desc3")}
                    <IconTimes width="20" height="20" fill="#22c55e" />
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="box v2">
                <p>{t(basePath + "block3.desc2")}</p>
              </div>
            </Col>
          </Row>
          <div className="btn">
            <Button
              href="https://t.me/BoostExWallet"
              type="default"
              className="xs"
            >
              {t(basePath + "block3.btn")}
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
