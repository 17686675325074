import React from "react";

const IconCopy = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      stroke={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="SVGRepo_iconCarrier">
        <path
          d="M16.5 6.5H19.5V21.5H8.5V18.5M10 3.5V8H5.5M10 3.5H16.5V18.5H5.5V8L10 3.5Z"
          stroke={fill}
          strokeWidth="1.2"
        ></path>
      </g>
    </svg>
  );
};

export default IconCopy;
