import { Button, Col, Row } from "antd";
import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();
  const basePath = "team.";

  return (
    <div className="team">
      <NavBar />

      <section className="banner">
        <Row align="middle">
          <Col xl={14} lg={14} md={14} sm={24} xs={24}>
            <h1>{t(basePath + "title")}</h1>
            <p>{t(basePath + "desc")}</p>
            <div className="btn-group">
              <Button href="https://t.me/BoostExWallet" type="primary">
                {t(basePath + "btn")}
              </Button>
            </div>
          </Col>
          <Col xl={10} lg={10} md={10} sm={24} xs={24}>
            <img src={require("../assets/img/myteam.png")} alt="" />
          </Col>
        </Row>
      </section>

      <section className="info">
        <div className="box">
          <Row align="middle">
            <Col xl={14} lg={14} md={24} sm={24} xs={24}>
              <img src={require("../assets/img/fire2.png")} alt="" />
            </Col>
            <Col xl={10} lg={10} md={24} sm={24} xs={24}>
              <h3>{t(basePath + "box1.title")}</h3>
              <p>{t(basePath + "box1.item1")}</p>
              <p>{t(basePath + "box1.item2")}</p>
            </Col>
          </Row>
        </div>
      </section>

      <div className="table-section">
        <h1>{t(basePath + "table.title1")}</h1>
        <div className="table-wrapper overflow-x-auto">
          <table className="w-full !text-white" id="table-team">
            <thead>
              <tr>
                <th></th>
                <th>3 {t(basePath + "table.months1")}</th>
                <th>6 {t(basePath + "table.months2")}</th>
                <th>9 {t(basePath + "table.months2")}</th>
                <th>12 {t(basePath + "table.months2")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td>
                  <span className="flex gap-2">
                    1 {t(basePath + "table.level")}{" "}
                    <strong>{t(basePath + "table.name.item1")}</strong>
                  </span>
                </td>
                <td>3%</td>
                <td>4%</td>
                <td>5%</td>
                <td>7%</td>
                <td className="text-sm">
                  <strong>
                    {t(basePath + "table.text.item1")} <br />{" "}
                    {t(basePath + "table.text.item2")} <br />{" "}
                    {t(basePath + "table.text.item3")}
                  </strong>
                </td>
              </tr>
              <tr className="">
                <td>
                  <span className="flex gap-2">
                    2 {t(basePath + "table.level")}
                    <strong>{t(basePath + "table.name.item2")}</strong>
                  </span>
                </td>
                <td>1,5%</td>
                <td>2%</td>
                <td>2,5%</td>
                <td>3,5%</td>
                <td>
                  {t(basePath + "table.turnover")}
                  <br />
                  <strong>100 000$</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="flex gap-2">
                    3 {t(basePath + "table.level")}
                    <strong>{t(basePath + "table.name.item3")}</strong>
                  </span>
                </td>
                <td>0,75%</td>
                <td>1%</td>
                <td>1,25%</td>
                <td>1,75%</td>
                <td>
                  {t(basePath + "table.turnover")}
                  <br />
                  <strong>250 000$</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="flex gap-2">
                    4 {t(basePath + "table.level")}
                    <strong>{t(basePath + "table.name.item4")}</strong>
                  </span>
                </td>
                <td>0,3%</td>
                <td>0,5%</td>
                <td>0,6%</td>
                <td>0,8%</td>
                <td>
                  {t(basePath + "table.turnover")}
                  <br />
                  <strong>500 000$</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="flex gap-2">
                    5 {t(basePath + "table.level")}
                    <strong>{t(basePath + "table.name.item5")}</strong>
                  </span>
                </td>
                <td>0,2%</td>
                <td>0,25%</td>
                <td>0,3%</td>
                <td>0,5%</td>
                <td>
                  {t(basePath + "table.turnover")}
                  <br />
                  <strong>1 000 000$</strong>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="table-footer">
            <p>{t(basePath + "table.desc1")}</p>
          </div>
        </div>

        <h1 className="mt-50">{t(basePath + "table.title2")}</h1>
        <div className="table-wrapper overflow-x-auto">
          <table className="w-full !text-white" id="table-team">
            <thead>
              <tr>
                <th></th>
                <th>3 {t(basePath + "table.months1")}</th>
                <th>6 {t(basePath + "table.months2")}</th>
                <th>9 {t(basePath + "table.months2")}</th>
                <th>12 {t(basePath + "table.months2")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td>
                  <span className="flex gap-2">
                    1 {t(basePath + "table.level")}{" "}
                    <strong>{t(basePath + "table.name.item1")}</strong>
                  </span>
                </td>
                <td>5%</td>
                <td>6%</td>
                <td>7%</td>
                <td>10%</td>
                <td className="text-sm">
                  <strong>
                    {t(basePath + "table.text.item1")} <br />{" "}
                    {t(basePath + "table.text.item2")} <br />{" "}
                    {t(basePath + "table.text.item3")}
                  </strong>
                </td>
              </tr>
              <tr className="">
                <td>
                  <span className="flex gap-2">
                    2 {t(basePath + "table.level")}
                    <strong>{t(basePath + "table.name.item6")}</strong>
                  </span>
                </td>
                <td>2,5%</td>
                <td>3%</td>
                <td>3,5%</td>
                <td>5%</td>
                <td>
                  {t(basePath + "table.turnover")}
                  <br />
                  <strong>50 000$</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="flex gap-2">
                    3 {t(basePath + "table.level")}
                    <strong>{t(basePath + "table.name.item7")}</strong>
                  </span>
                </td>
                <td>1,25%</td>
                <td>1,5%</td>
                <td>1,75%</td>
                <td>2,5%</td>
                <td>
                  {t(basePath + "table.turnover")}
                  <br />
                  <strong>125 000$</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="flex gap-2">
                    4 {t(basePath + "table.level")}
                    <strong>{t(basePath + "table.name.item8")}</strong>
                  </span>
                </td>
                <td>0,6%</td>
                <td>0,75%</td>
                <td>0,8%</td>
                <td>1,25%</td>
                <td>
                  {t(basePath + "table.turnover")}
                  <br />
                  <strong>250 000$</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="flex gap-2">
                    5 {t(basePath + "table.level")}
                    <strong>{t(basePath + "table.name.item9")}</strong>
                  </span>
                </td>
                <td>0,3%</td>
                <td>0,4%</td>
                <td>0,5%</td>
                <td>0,6%</td>
                <td>
                  {t(basePath + "table.turnover")}
                  <br />
                  <strong>500 000$</strong>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="table-footer">
            <p>{t(basePath + "table.desc2")}</p>
          </div>
        </div>
      </div>

      <section className="info">
        <div className="box">
          <Row align="middle">
            <Col xl={10} lg={10} md={24} sm={24} xs={24}>
              <p>
                {t(basePath + "info.text1")}{" "}
                <a href="https://t.me/BoostExWallet">BoostEx</a>{" "}
                {t(basePath + "info.text2")}
              </p>
            </Col>
            <Col xl={14} lg={14} md={24} sm={24} xs={24}>
              <img src={require("../assets/img/check2.png")} alt="" />
            </Col>
          </Row>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Team;
