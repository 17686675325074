import React from "react";

const IconCheck = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="9.963" cy="9.988" r="9.25" fill={fill}></circle>
      <path
        stroke="#F4F4F7"
        strokeWidth="2.167"
        d="m5.357 9.785 3.147 3.147 5.885-5.885"
      ></path>
    </svg>
  );
};

export default IconCheck;
