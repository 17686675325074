import { Button, Col, Row } from "antd";
import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Checks = () => {
  const { t } = useTranslation();
  const basePath = "checks.";

  return (
    <div className="checks">
      <NavBar />

      <section className="banner">
        <Row align="middle">
          <Col xl={14} lg={14} md={24} sm={24} xs={24}>
            <h1>{t(basePath + "title")}</h1>
            <p>{t(basePath + "desc")}</p>
            <div className="btn-group">
              <Button href="https://t.me/BoostExWallet" type="primary">
                {t(basePath + "btn")}
              </Button>
            </div>
          </Col>
          <Col xl={10} lg={10} md={24} sm={24} xs={24}>
            <img src={require("../assets/img/check.svg").default} alt="" />
          </Col>
        </Row>
      </section>

      <section className="info">
        <div className="box">
          <Row align="middle">
            <Col xl={14} lg={14} md={24} sm={24} xs={24}>
              <img src={require("../assets/img/binocle.png")} alt="" />
            </Col>
            <Col xl={10} lg={10} md={24} sm={24} xs={24}>
              <h3>{t(basePath + "block1.title")}</h3>
              <p>{t(basePath + "block1.item1")}</p>
              <p>{t(basePath + "block1.item2")}'</p>
              <p>{t(basePath + "block1.item3")}</p>
              <p>{t(basePath + "block1.item4")}</p>
            </Col>
          </Row>
        </div>
      </section>

      <section className="other">
        <Row>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className="box">
              <h3>{t(basePath + "block2.title")}</h3>
              <p>
                {t(basePath + "block2.item1")}
                <br />
                {t(basePath + "block2.item2")}
                <br />
                {t(basePath + "block2.item3")}
                <br />
                {t(basePath + "block2.item4")}
                <br />
                {t(basePath + "block2.item5")}
                <br />
                {t(basePath + "block2.item6")}
              </p>
              <img src={require("../assets/img/check1.png")} alt="" />
            </div>
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className="box">
              <h3>{t(basePath + "block3.title")}</h3>
              <p>{t(basePath + "block3.desc")}</p>
              <img src={require("../assets/img/check2.png")} alt="" />
            </div>
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className="box">
              <h3>{t(basePath + "block4.title")}</h3>
              <p>{t(basePath + "block4.desc")}</p>
              <img src={require("../assets/img/check3.png")} alt="" />
            </div>
          </Col>
        </Row>
      </section>

      <Footer />
    </div>
  );
};

export default Checks;
